import React from 'react'
import {
  CFLink,
  CFView,
  CFImage,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import ViewMenu from './ViewMenu'
import { hero, heroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100vh"
          w="100%"
          image={`url(${hero}) 22% / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
        >
          <Header />
          <CFView column center mv="60px" ph="10px">
            <CFView pb="15px">
              <CFView column center bg="rgba(0,0,0,0.7)" p="10px" br="8px">
                <CFView bold h2 textCenter color="white">
                  <CFImage src={heroText} w="85vw" alt="About" />
                </CFView>
                <CFView ml="10px" mt="10px" w="100%" column center>
                  <OrderPickupButton />
                  <ViewMenu />
                </CFView>
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="700px"
          w="100%"
          image={`url(${hero}) bottom / cover no-repeat`}
          boxShadow="0 2px 5px rgba(0,0,0,.5)"
          zIndex={90}
        >
          <Header />
          <CFView row justifyEnd alignCenter h="660px">
            <CFView column center mr="4%" mt="-25px">
              <CFImage src={heroText} h="450px" alt="Now taking online order" />
              <CFView mt="15px">
                <OrderPickupButton />
                {/* <OrderDeliveryButton /> */}
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
