import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import {
  CFView,
  CFImage,
  DefaultScreen,
  MobileScreen,
  CFLink,
} from 'components'
import {
  headerBg,
  logo,
  logoTitle,
  mobileLogoTitle,
  viewMenu,
  menu1,
  menu2,
  menu2021,
} from 'images'
import ViewMenu from './ViewMenu'

const orderingUrl =
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://demo.codefusion.tech/restaurants/ebisu/locations/ebisuLocation1'
    : 'https://order.codefusion.tech/restaurants/jwtXW2Bc6tUEEYtcnIIt/locations/MvLV4OUJjaddWhfyWwXM'

const images = [
  {
    src: menu1,
  },
  {
    src: menu2,
  },
]

export default () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <MobileScreen>
        <CFView
          column
          center
          h="85px"
          image={`url(${headerBg}) center / cover no-repeat`}
          boxShadow="0 1px 3px rgba(0,0,0,.8)"
        >
          <CFView row center>
            <CFImage
              h="65px"
              mt="5px"
              src={logo}
              alt="Chimac Korean Fried Chicken & Pub Logo"
            />
            <CFImage
              h="35px"
              ml="10px"
              mt="5px"
              src={mobileLogoTitle}
              alt="Chimac Korean Fried Chicken & Pub Logo Title"
            />
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          row
          justifyStart
          h="70px"
          image={`url(${headerBg}) center / cover no-repeat`}
          boxShadow="0 1px 3px rgba(0,0,0,.8)"
          zIndex={99}
        >
          <CFView
            row
            justifyBetween
            alignCenter
            w="100%"
            maxWidth="1400px"
            ph="50px"
          >
            <CFView row justifyStart mt="75px">
              <CFImage
                h="140px"
                mt="5px"
                src={logo}
                alt="Chimac Korean Fried Chicken & Pub Logo"
                zIndex={99}
              />
              <CFImage
                h="50px"
                mt="15px"
                ml="15px"
                src={logoTitle}
                alt="Chimac Korean Fried Chicken & Pub Logo Title"
              />
            </CFView>
            <CFView hover mt="12px">
              <ViewMenu />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
      <ModalGateway>
        {showModal ? (
          <Modal onClose={() => setShowModal(false)}>
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
